import api from "@a/config";

export default {
  getMitarbeiter: () => {
    return api.get("v1/mitarbeiter");
  },
  getBeschwerdeKategorien: () => {
    return api.get("v1/beschwerde/kategorie");
  },
  getAnreden: () => {
    return api.get("v1/anrede");
  },
  getBeschwerdeFormen: () => {
    return api.get("v1/beschwerde/form");
  },
  getAbteilungen: () => {
    return api.get("v1/abteilung");
  },
  getStandorte: () => {
    return api.get("v1/standort");
  },
  getLinien: () => {
    return api.get("v1/linie");
  },
  getHaltestellen: () => {
    return api.get("v1/haltestelle");
  }
};
