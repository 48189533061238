import api from "@a/";
import { LOAD_ABTEILUNGEN, LOAD_BESCHWERDEFORM, LOAD_BESCHWERDEKATEGORIE, LOAD_MITARBEITER, LOAD_STANDORTE, LOAD_LINIEN, LOAD_ANREDEN, LOAD_HALTESTELLEN } from "./actions.type";
import { SET_ABTEILUNGEN, SET_BESCHWERDEFORM, SET_BESCHWERDEKATEGORIE, SET_MITARBEITER, SET_STANDORTE, SET_LINIEN, SET_ANREDEN, SET_HALTESTELLEN, ADD_HALTESTELLE } from "./mutations.type";

export default {
  namespaced: true,
  state: {
    mitarbeiter: [],
    beschwerdeformen: [],
    beschwerdekategorien: [],
    standorte: [],
    abteilungen: [],
    linien: [],
    anreden: [],
    haltestellen: []
  },
  mutations: {
    [SET_MITARBEITER](state, val) {
      state.mitarbeiter = val;
    },
    [SET_BESCHWERDEFORM](state, val) {
      state.beschwerdeformen = val;
    },
    [SET_BESCHWERDEKATEGORIE](state, val) {
      state.beschwerdekategorien = val;
    },
    [SET_STANDORTE](state, val) {
      state.standorte = val;
    },
    [SET_ABTEILUNGEN](state, val) {
      state.abteilungen = val;
    },
    [SET_LINIEN](state, val) {
      state.linien = val;
    },
    [SET_ANREDEN](state, val) {
      state.anreden = val;
    },
    [SET_HALTESTELLEN](state, val) {
      state.haltestellen = val;
    },
    [ADD_HALTESTELLE](state, val) {
      state.haltestellen.push(val);
    }
  },
  actions: {
    async [LOAD_MITARBEITER](context) {
      await context.commit(SET_MITARBEITER, (await api.kategorien.getMitarbeiter()).data);
    },
    async [LOAD_BESCHWERDEFORM](context) {
      await context.commit(SET_BESCHWERDEFORM, (await api.kategorien.getBeschwerdeFormen()).data);
    },
    async [LOAD_BESCHWERDEKATEGORIE](context) {
      await context.commit(SET_BESCHWERDEKATEGORIE, (await api.kategorien.getBeschwerdeKategorien()).data);
    },
    async [LOAD_STANDORTE](context) {
      await context.commit(SET_STANDORTE, (await api.kategorien.getStandorte()).data);
    },
    async [LOAD_ABTEILUNGEN](context) {
      await context.commit(SET_ABTEILUNGEN, (await api.kategorien.getAbteilungen()).data);
    },
    async [LOAD_LINIEN](context) {
      await context.commit(SET_LINIEN, (await api.kategorien.getLinien()).data);
    },
    async [LOAD_ANREDEN](context) {
      await context.commit(SET_ANREDEN, (await api.kategorien.getAnreden()).data);
    },
    async [LOAD_HALTESTELLEN](context) {
      await context.commit(SET_HALTESTELLEN, (await api.kategorien.getHaltestellen()).data);
    }
  },
  getters: {
    mitarbeiter: state => state.mitarbeiter,
    beschwerdeformen: state => state.beschwerdeformen,
    beschwerdekategorien: state => state.beschwerdekategorien,
    standorte: state => state.standorte,
    abteilungen: state => state.abteilungen,
    linien: state => state.linien,
    anreden: state => state.anreden,
    haltestellen: state => state.haltestellen
  },
};
