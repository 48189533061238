import axios from "axios";
import store from "@/store";
//import server from "@/server";

import { API_START_LOADING, API_FINISH_LOADING } from "@st/mutations.type";

const config = {
  withCredentials: true,
  baseURL: process.env.VUE_APP_API_URL,
};

if (process.env.NODE_ENV === "development") {
  config.auth = {
    username: process.env.VUE_APP_USERNAME,
    password: process.env.VUE_APP_PASSWORD,
  };
}

const api = axios.create(config);

// Additional Config and Interceptors here

api.interceptors.request.use(
  (config) => {
    store.commit(API_START_LOADING);
    return config;
  },
  (error) => {
    store.commit(API_FINISH_LOADING);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    store.commit(API_FINISH_LOADING);
    return response;
  },
  (error) => {
    store.commit(API_FINISH_LOADING);

    if (error.response.status === 401) {
      console.log('Interceptor Response Error', error);
      if (error.request.responseURL.indexOf("user/current")< 0) {
        // NICHT User-Check -> lade durch und gehe per Navigation-Guard zum Login
        window.location.reload();
      }


      /*const params = new URLSearchParams();
      params.append("redir", window.location.href);

      let newRoute = `${window.location.protocol}//${window.location.host}/intranet/login?${params.toString()}`;
      window.location.href = newRoute;*/
    }

    return Promise.reject(error);
  }
);

export default api;
