import api from "@a/config";

export default {
  getTickets: () => {
    return api.get("v1/ticket");
  },
  closeTicket: (ticket) => {
    return api.delete(`v1/ticket/${ticket.id}`)
  }
};
