import { SET_FILTER, SET_TOTAL } from "./mutations.type";

export default {
  namespaced: true,
  state: {
    filter: {
      sort: null,
      desc: false,
      page: 0,
      count: 15,
      search: '',
    },
    total: 0
  },
  mutations: {
    [SET_FILTER](state, val) {
      state.filter = val;
    },
    [SET_TOTAL](state, val) {
      state.total = val;
    },
  },
  actions: {

  },
  getters: {},
};
