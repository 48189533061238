import api from "@a/config";

export default {
  getBeschwerden: (filter) => {
    return api.get("v1/beschwerde",{params:filter});
  },
  getBeschwerde: (id) => {
    return api.get(`v1/beschwerde/${id}`);
  },
  updateBeschwerde: (beschwerde) => {
    return api.put(`v1/beschwerde/${beschwerde.id}`, beschwerde);
  },
  patchBeschwerde: (id, data) => {
    return api.patch(`v1/beschwerde/${id}`, data)
  },
  createBeschwerde: (beschwerde) => {
    return api.post("v1/beschwerde", beschwerde);
  },
  createBeschwerdeschritt: (schritt) => {
    return api.post("v1/beschwerde/schritt", schritt);
  },
  updateBeschwerdeschritt: (schritt) => {
    return api.put(`v1/beschwerde/schritt/${schritt.id}`, schritt);
  },
  sendZwischenbescheid: (beschwerde_id, mail, per_post, per_mail, subject, text) => {
    return api.post("v1/beschwerde/zwischenbescheid", {beschwerde_id: beschwerde_id, mail: mail, per_mail: per_mail, per_post: per_post, subject: subject, text: text});
  },
  getZwischenbescheid: (beschwerde_id) => {
    if (beschwerde_id)
      return api.get(`v1/beschwerde/zwischenbescheid/${beschwerde_id}`)
    else
      return api.get('v1/beschwerde/zwischenbescheid/')
  },
  sendAntwortBeschwerdefuehrer: (beschwerde_id, mail, subject, text) => {
    return api.post("v1/beschwerde/antwort_beschwerdefuehrer", {beschwerde_id: beschwerde_id, mail: mail, subject: subject, text: text});
  },
  getAntwortBeschwerdefuehrer: (beschwerde_id) => {
    if (beschwerde_id)
      return api.get(`v1/beschwerde/antwort_beschwerdefuehrer/${beschwerde_id}`)
    else
      return api.get('v1/beschwerde/antwort_beschwerdefuehrer/')
  },
  uploadDocuments: (beschwerde_id, beschwerdeschritt_id, files) => {
    let formData = new FormData();
    formData.append("beschwerde", beschwerde_id);
    if (beschwerdeschritt_id)
    formData.append("beschwerdeschritt", beschwerdeschritt_id);

    files.forEach((f) => {
      formData.append("files", f);
    });

    return api.post(`v1/beschwerde/dokument/`, formData, {
      headers: {
        'Content-Type': 'multipart/formdata'
      }
    })
  }
};
