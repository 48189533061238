import api from "@a/config";

export default {
  getCurrent: () => {
    return api.get("v1/user/current", { withCredentials: true })
  },
  setPassword: (password) => {
    return api.patch("v1/user/current", {password: password}, {withCredentials: true})
  }
}
