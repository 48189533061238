import Vue from "vue";
import Vuex from "vuex";

import {API_FINISH_LOADING, API_START_LOADING} from "./mutations.type";

import session from "./session";
import tickets from "./tickets";
import beschwerden from "./beschwerden";
import kategorien from "./kategorien";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiLoading: 0,
  },
  mutations: {
    [API_START_LOADING](state) {
      state.apiLoading++;
    },
    [API_FINISH_LOADING](state) {
      state.apiLoading--;
    },
  },
  actions: {},
  modules: {
    session,
    tickets,
    beschwerden,
    kategorien
  },
  getters: {
    apiIsLoading: (state) => state.apiLoading > 0,
  }
});
