import { SET_FILTER, SET_MEISTERBEREICHFILTER, SET_TICKETS } from "./mutations.type";
import { LOAD_TICKETS } from "./actions.type";
import api from "@a/";

export default {
  namespaced: true,
  state: {
    tickets: [],
    filter: {
      sort: null,
      desc: false,
      page: 0,
      count: 15,
      search: '',
    },
    meisterbereichfilter: {
      sort: null,
      desc: false,
      page: 0,
      count: 15,
      search: '',
    }
  },
  mutations: {
    [SET_TICKETS](state, val) {
      state.tickets = val;
    },
    [SET_FILTER](state, val) {
      state.filter = val;
    },
    [SET_MEISTERBEREICHFILTER](state, val) {
      state.meisterbereichfilter = val;
    }
  },
  actions: {
    async [LOAD_TICKETS](context) {
      // TODO: Return promise for item provider
      context.commit(SET_TICKETS, (await api.tickets.getTickets()).data);
    },
  },
  getters: {
    allTickets: state => {
      return state.tickets
    },
    normalTickets: state => {
      return state.tickets.filter((t) => t.prio === 0)
    },
    prioTickets: state => {
      return state.tickets.filter((t) => t.prio === 1)
    },
    overdueTickets: state => {
      return state.tickets.filter((t) => t.prio === 2)
    },
    ownTickets: (state, _getters, rootState) => {
      return state.tickets.filter((t) => rootState.session.user == null || (t.mitarbeiter != null && t.mitarbeiter.id === rootState.session.user.mitarbeiter_id) || (t.abteilung != null && t.abteilung.id === rootState.session.user.abteilung))
    },
    meisterbereichTickets: (state, _getters, rootState) => {
      return state.tickets.filter((t) => rootState.session.user != null && (t.mitarbeiter == null || t.mitarbeiter.id !== rootState.session.user.mitarbeiter_id) && (t.abteilung == null || t.abteilung.id !== rootState.session.user.abteilung))
    }
  },
};
