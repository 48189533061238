import api from "@a/config";

export default {
  importDocument: (standort_id, date, file) => {
    let formData = new FormData();
    formData.append("standort", standort_id);
    formData.append("date", date);  // dd.MM.yyyy
    formData.append("file", file);

    return api.post(`api/tdp_displays/import/`, formData, {
      baseURL: '/',
      headers: {
        'Content-Type': 'multipart/formdata'
      }
    })
  }
};
