import IndustrialTheme from "@bx/batix-industrial-ui-framework";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { abilitiesPlugin } from '@casl/vue'
import { Ability } from '@casl/ability'
import moduleabilities from './moduleabilities'
import Permission from "./permission";
import VueSelect from 'vue-select'

/**
 * Setup Plugins for the Vue instance
 * @param {VueConstructor<Vue>} instance
 */
 export function setupPlugins (instance) {

  instance.use(IndustrialTheme);
  instance.use(BootstrapVue);
  instance.use(IconsPlugin);
  instance.use(Permission);
  instance.use(abilitiesPlugin, new Ability([]));
  instance.use(moduleabilities);

}

export function setupComponents (instance) {
  instance.component("v-select", VueSelect);
}
