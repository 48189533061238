import api from "@a/";
import router from "../../router";
import axios from "axios";
import { LOAD_USER, LOGIN, LOGOUT } from "./actions.type";
import { SET_USER } from "./mutations.type";

export default {
  namespaced: true,
  state: {
    user: null,
    datenstand: null
  },
  mutations: {
    [SET_USER] (state, val) {
      state.user = val
    },
  },
  actions: {
    async [LOAD_USER] (context) {
      context.commit(SET_USER, (await api.user.getCurrent()).data)
    },
    async [LOGIN] (context, [username, password]) {
      let params = new URLSearchParams()
      params.append("username", username)
      params.append("password", password)

      await axios.post("/login/", params);
      await context.dispatch(LOAD_USER);

    },
    async [LOGOUT] (context) {
      await axios.post("/logout/", null, { withCredentials: true })
      context.commit(SET_USER, null)
      router.push({ name: "login" })
    }
  },
  modules: {
  },
  getters: {
    username: state => state.user?.full_name,
    isAdmin: state => state.user?.groups?.filter(g => g.id === "17B1225AB83")?.length > 0,
    isLoggedIn: state => state.user !== null,
    groups: state => state.user.groups
  }
}
